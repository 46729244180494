@mixin transition($duration) {
    -webkit-transition: $duration cubic-bezier(0.375, 0.885, 0.6, 1) all;
    -moz-transition: $duration cubic-bezier(0.375, 0.885, 0.6, 1) all;
    -o-transition: $duration cubic-bezier(0.375, 0.885, 0.6, 1) all;
    transition: $duration cubic-bezier(0.375, 0.885, 0.6, 1) all;
}

@mixin transition-property($duration, $property, $delay) {
    -webkit-transition: $property $duration cubic-bezier(0.375, 0.885, 0.6, 1) $delay;
    -moz-transition: $property $duration cubic-bezier(0.375, 0.885, 0.6, 1) $delay;
    -o-transition: $property $duration cubic-bezier(0.375, 0.885, 0.6, 1) $delay;
    transition: $property $duration cubic-bezier(0.375, 0.885, 0.6, 1) $delay;
}

@mixin background-gradient($base-color, $shine-color) {
  background-image: linear-gradient(90deg, $base-color 0px, $shine-color 40px, $base-color 80px);
  background-size: 600px;
}