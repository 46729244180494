@import '../colors.scss';
@import '../mixins.scss';

.form-group {
  label {
    font-size: 0.75rem;
    margin-bottom: .0rem;
    font-weight: 300;
  }
  .form-control {
    font-size: 1rem;

    &, &:focus, &:active {
      padding-left: 0;
      padding-right: 0;
      border: none;
      background-color: transparent;
      color: inherit;
      border-bottom: 1px solid #ffffff;
      border-radius: 0;
      height: 56px;
    }

    &::placeholder {
      color: #80C9FF;
      text-overflow: ellipsis;
      opacity: 1;
    }
    &::-webkit-input-placeholder {
      color: #80C9FF;
      text-overflow: ellipsis;
      opacity: 1;
    }
    &::-moz-placeholder, &:-moz-placeholder,&:-ms-input-placeholder {
      color: #80C9FF;
      text-overflow: ellipsis;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #80C9FF;
      text-overflow: ellipsis;
      opacity: 1;
    }
  }
}