@import '../colors.scss';
@import '../mixins.scss';

.logo-wrapper {
  z-index: 1200;

  .logo {
    position: relative;
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.69px;
    font-style: normal;
    font-weight: 900;
    color: $dark;
    text-transform: uppercase;
    display: block;
    z-index: 10000;
    transition: 0.15s cubic-bezier(0.375, 0.885, 0.6, 1) all, 0s cubic-bezier(0.375, 0.885, 0.6, 1) top;

    @media (max-width: 991px) and (min-width: 768px) {
      line-height: 36px;
      font-size: 26px;
    }
    @media (max-width: 440px) {
      line-height: 36px;
      font-size: 26px;
    }
    @media (max-width: 767px) {
    }

    &, &:focus, &:active {
      &, &:focus, &:active {
        color: $dark;
      }
    }
    
    &:hover {
      &, &:focus, &:active, &:hover {
        color: $main;
        @include transition(0.35s);
      }
    }

    span {
      padding: 0.0625rem 0.75rem;
      display: block;
    }
  }
}
header {
  .navbar {
    padding-top: 2.75rem;
    padding-bottom: 1rem;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 767px) {
      padding-top: 1.5rem;
    }

    @media (max-width: 440px) {
      justify-content: center;
    }

    .navbar-brand {
      position: relative;
      z-index: 1200;
      padding-left: 0;
      padding-right: 0;
    }
    .btn {
      border-radius: 20px;
      padding: 0.4rem 1.25rem;
      position: relative;
      z-index: 20;
    }

    .navbar-nav {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @include transition(0.15s);
      box-shadow: 0px 0px 0px 0 rgba(33,39,56,0.01);

      @media (max-width: 360px) {
        justify-content: center;
      }

      & > * {
        margin-left: 15px;

        @media (max-width: 575px) {
          margin-left: 0;
        }
      }
      .nav-item {
        a {
          &.nav-link {
            text-transform: uppercase;
            color: $SURF_GRAY;


            sup {
              color: $SURF_GREEN;
              color: #2FA851;
            }
            
            &:hover, &.active {
              color: $SURF_BLUE;

              sup {
                color: $SURF_BLUE;
              }
            }

            @media (max-width: 575px) {
              padding: 1rem 0.25rem;
              position: relative;

              &:after {
                position: absolute;
                top: 1.25rem;
                right: 0.5rem;
              }
            }
          }
        }
        &:not(.show) {
          a {
            &.nav-link {
              @media (max-width: 575px) {
                border-bottom: 1px solid rgba($SURF_TERTIARY, 0.2);
              }
            }
          }
        }
      }
    }
    a {
      &.nav-link {
        color: $dark !important;
        text-transform: uppercase;

        &:hover, &.active {
          color: $SURF_BLUE;
        }
      }
      &.btn-icon {
        padding-right: 1rem;

        @media (max-width: 575px) {
          padding: 1rem 0.25rem;
          position: relative;
        }
      }
      &.outer {
        position: relative;
        display: block;
        padding-right: 1rem !important;
        @include transition(0.15s);

        &:after {
          content: "";
          width: 1rem;
          height: 1rem;
          position: absolute;
          background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%233B4043' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center;
          right: 0;
          top: calc(50% - 10px);
          @include transition(0.15s);
        }
        &:hover {
          color: $SURF_BLUE !important;
          @include transition(0.35s);

          &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%230083E0' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
              @include transition(0.35s);
          }
        }
      }
    }
    .navbar-toggler {
      border: none;
      position: relative;
      width: 54px;
      height: 40px;
      z-index: 2;
      margin-right: 0.5rem;

      &, &:hover, &:focus, &:active {
        &, &:hover, &:focus, &:active {
          border: none;
          box-shadow: none;
          outline: none;
        }
      } 

      .navbar-toggler-icon {
        background-image: none;
        display: none;
      }

      &:before, &:after {
        content: '';
        height: 2px;
        width: 24px;
        background-color: $SURF_GRAY;
        position: absolute;
        @include transition(0.15s);
        left: 15px;
      }
      &:before {
        top: 15px;
      }
      &:after {
        top: 21px;
      }

      &:not(.collapsed) {
        &:before {
          transform: rotate(45deg) translateY(2px) translateX(2px);
        }
        &:after {
          transform: rotate(-45deg) translateY(-2px) translateX(2px);
        }
      }

      &:hover {
        &:before, &:after {
          background-color: $SURF_BLUE;
          @include transition(0.35s);

        }
      }
    }
  }
}

.isMobile {
  header {
    .navbar-nav {
      @media (max-width: 440px) {
        display: none;
      }
    }
  }
}