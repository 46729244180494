@import '../colors.scss';
@import '../mixins.scss';

//
// Base styles
//

.accordion {
  &.content-wrapper {
    padding: 0;
    margin: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 1rem 0;
  font-size: 1.125rem;
  font-weight: 600;
  color: $SURF_GRAY;
  text-align: left; // Reset button style
  background-color: transparent;
  border: 0;
  overflow-anchor: none;
  @include transition(0.5s);
  font-style: normal;
  font-weight: 900;
  font-size: 17px;
  line-height: 24px;
  letter-spacing: -0.22px;
  text-transform: uppercase;

  &:not(.collapsed) {
    color: $SURF_GRAY;
    background-color: transparent;
    border-radius: 0 0 0 0;

    &::after {
      transform: rotate(-180deg);
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 5L0 0H8L4 5Z' fill='%230185E9'/%3E%3C/svg%3E%0A");
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: .5rem;
    content: "";
    transform: rotate(0deg);
    background-image: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 5L0 0H8L4 5Z' fill='%230185E9'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: .65rem;
    @include transition(0.15s);
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: #86b7fe;
    outline: 0;
    box-shadow: none;
  }
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: transparent;
  border: none;
  @include transition(0.15s);

  .accordion-header {
    position: relative;

    &:before {
      position: absolute;
      top: 0px;
      height: 1px;
      left: 0;
      right: 0;
      background-color: #E2E3E4;
      content: '';

    }
  }
  &:first-of-type {
    .accordion-header:before {
      content: none;
    }
  }

  &.active {
    @include transition(0.35s);


    .accordion-header {
      position: relative;
    }
  }

  &.active ~ & {
    .accordion-header {
      &:before {
      }
    }
  }
  &.active ~ & ~ & {
    .accordion-header {
      &:before {
        content: '';
      }
    }
  }

  .accordion-collapse {
  }
}

.accordion-body {
  padding: 1.5rem 0;

  @media (max-width: 991px) {
    padding: 1.5rem 0;
  }

  ul {
    padding-left: 0;

    li {
      list-style-type: none;

      &:before {
        content: "" !important;
        width: 8px !important;
        height: 8px !important;
        background-color: $main !important;
        display: inline-block !important;
        vertical-align: middle !important;
        margin-top: -4px !important;
        margin-right: 1rem !important;
      }
    }
  }
}


// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-right: 0;
    border-left: 0;

    &:first-child { border-top: 0; }
    &:last-child { border-bottom: 0; }

    .accordion-button {
    }
  }
}
