@import './colors.scss';
@import './mixins.scss';

@import './components/buttons.scss';
@import './components/forms.scss';
@import './components/accordion.scss';
@import './components/typography.scss';


html {
  font-size: 16px;
}

body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  letter-spacing: -.01em;
  background-color: white;
}

a {
  @include transition(0.15s);
  color: $main;

  &, &:focus, &:active, &:hover {
    &, &:focus, &:active, &:hover {
      text-decoration: none;
      outline: none;
    }
  }
  &:focus, &:active, &:hover {
    @include transition(0.35s);
    color: darken($main, 10);
  }
  &.active {
    color: darken($main, 10);
  }
}

.ws-app {
  display: flex;
  flex-direction: column;
}

.container-fluid {
  padding-left: 16px;
  padding-right: 16px;
}
main {
  & > * {
    padding-bottom: 4rem;
    padding-top: 4rem;
    
    &:first-child {
      padding-top: 0rem;
      overflow: hidden;
    }

    @media (max-width: 991px) and (min-width: 768px) {
    }
  }
}
footer {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $main;
  color: $white;
  height: auto;
}
div[class^="container"], div[class*=" container-"] {

  @media (max-width: 767px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

header {
  flex-shrink: 0;
}
main {
  flex: 1 0 auto;
  overflow: hidden;
}
footer {
  flex-shrink: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 2.4rem;
  letter-spacing: -0.03em;

  small {
    font-size: 30%;
  }
}

.gray-back {
  background-color: $gray_background;
  overflow: hidden;
}

.hero {

  display: grid;
  grid-template-columns: [first] 350px [line2] auto [last];
  grid-template-rows: [start] 75px [row-0] 200px [row-1] 160px [row-2] 100px [end];
  grid-template-areas: 
  "spacer void"
  "header void"
  "percentage void"
  "cta void";
  justify-items: start;
  align-items: start;
  justify-content: start;

  background-image: url('../images/illustrations/hero.png');
  background-position: right 70px top 10px;
  background-repeat: no-repeat;
  background-size: 550px auto;

  
  @media (max-width: 1199px) {
    background-image: url('../images/illustrations/hero.png');
    background-position: right 60px top 40px;
    background-repeat: no-repeat;
    background-size: 480px auto;
  }
  @media (max-width: 991px) {
    grid-template-columns: [first] 270px [line2] auto [last];
    grid-template-rows: [start] 75px [row-0] 170px [row-1] 130px [row-2] 100px [end];
    background-position: right 0px top 70px;
    background-size: 380px auto;
  }
  @media (max-width: 767px) {
    grid-template-columns: [first] 1fr [last];
    grid-template-areas: 
    "spacer"
    "header"
    "percentage"
    "cta";
    grid-template-rows: [start] calc(75vw) [row-0] 185px [row-1] 160px [row-2] 100px [end];
    background-position: center top 40px;
    background-size: 65vw auto;
    justify-items: center;
  }

  .header {
    grid-area: header;


    span {
      display: block;
      text-transform: uppercase;
      line-height: 72px;

      @media (max-width: 991px) and (min-width: 768px) {
        font-size: 56px;
        line-height: 56px;
      }
      @media (max-width: 440px) {
        text-align: center;
        font-size: calc(75vw / 4);
        line-height: calc(75vw / 4);
      }

      &:last-of-type {
        margin-left: 120px;

        @media (max-width: 991px) and (min-width: 768px) {
          margin-left: 80px;
        }
        @media (max-width: 440px) {
          margin-left: 0;
        }
      }
    }
    .subheader {
      margin-left: 120px;

      @media (max-width: 991px) and (min-width: 768px) {
        margin-left: 80px;
      }
      @media (max-width: 440px) {
        text-align: center;
        margin-left: 0;
      }
    }
  }
  .subheader {
    text-transform: uppercase;
  }
  .percentage {
    grid-area: percentage;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 767px) {
      justify-content: center;
    }
    
    .subheader {
      @media (max-width: 440px) {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: -0.04px;
      }

      b {
        color: $main;
        line-height: 100px;
        margin-left: 10px;
        margin-right: 10px;
        letter-spacing: -.3rem;

        @media (max-width: 991px) and (min-width: 768px) {
          font-size: 72px;
          line-height: 72px;
        }
        @media (max-width: 440px) {
          font-size: 72px;
          line-height: 72px;
        }
      }
      &.marketing-small {
        text-indent: 8px;

        @media (max-width: 991px) and (min-width: 768px) {
          margin-top: .25rem;
        }
        @media (max-width: 440px) {
          margin-top: .25rem;
          text-indent: 0;
        }
      }
    }
  }
  a {
    grid-area: cta;

    &, button {
      text-transform: uppercase;
      width: 100%;
    }
  }
}

.stat-cards {
  &.stat-cards-flex > * {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 991px) and (min-width: 768px) {
    margin-top: 2rem;
    margin-bottom: 0rem;
  }

  & > div {
    @media (max-width: 440px) {
      // flex: 0 0 100%;
      // max-width: 100%;
    }
  }
  
  .stat-card {
    margin-top: 4rem;
    margin-bottom: 1rem;
    position: relative;

    @media (max-width: 991px) and (min-width: 768px) {
      margin-top: 3rem;
      margin-bottom: 2rem;
    }

    & > * {
      z-index: 2;
      position: relative;
    }

    .header {

    }
    .body {
      letter-spacing: -1.2px !important;
      white-space: nowrap;

      @media (max-width: 575px) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.69px;
      }
    }
  }
  &:not(.stat-cards-flex) > *, &.stat-cards-flex > * {
    .stat-card:before {
      content: '';
      position: absolute;
      height: 150px;
      width: 200px;
      background-repeat: no-repeat;
      background-size: 150px auto;
      opacity: .5;
      top: -30px;
      left: -30px;
      filter: brightness(40%) sepia(100%) saturate(400%) hue-rotate(170deg); // main alt brightness(50%)
      filter: brightness(65%) sepia(100%) saturate(280%) hue-rotate(6deg); // yellow
      filter: brightness(55%) sepia(100%) saturate(280%) hue-rotate(57deg); // green
      filter: brightness(55%) sepia(100%) saturate(150%) hue-rotate(297deg); // pink
      filter: brightness(60%) sepia(100%) saturate(300%) hue-rotate(168deg); // blue
      filter: brightness(80%) sepia(100%) saturate(2%) hue-rotate(170deg);   // gray

      @media (max-width: 440px) {
        background-size: 120px auto;
        top: -10px !important;
        left: -20px !important;
      }
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(1) {
    @media (max-width: 440px) {
      flex: 0 0 65%;
      max-width: 65%;
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(1) .stat-card, &.stat-cards-flex > * .stat-card:nth-child(1) {
    min-width: 200px;

    &:before {
      background-image: url("../images/blobs/1.png");
      filter: brightness(50%) sepia(100%) saturate(400%) hue-rotate(170deg); // main

      @media (max-width: 767px) {
        left: 20px;
      }
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(2) {
    @media (max-width: 440px) {
      flex: 0 0 35%;
      max-width: 35%;
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(2) .stat-card, &.stat-cards-flex > * .stat-card:nth-child(2) {
    min-width: 100px;

    &:before {
      background-image: url("../images/blobs/2.png");
      filter: brightness(65%) sepia(100%) saturate(280%) hue-rotate(6deg); // yellow

      @media (max-width: 767px) {
        left: 20px;
      }
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(3) {
    @media (max-width: 440px) {
      flex: 0 0 65%;
      max-width: 65%;
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(3) .stat-card, &.stat-cards-flex > * .stat-card:nth-child(3) {
    min-width: 90px;
    
    &:before {
      background-image: url("../images/blobs/3.png");
      filter: brightness(55%) sepia(100%) saturate(280%) hue-rotate(57deg); // green

      @media (max-width: 767px) {
        left: 20px;
      }
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(4) {
    @media (max-width: 440px) {
      flex: 0 0 35%;
      max-width: 35%;
    }
  }
  &:not(.stat-cards-flex) > *:nth-child(4) .stat-card, &.stat-cards-flex > * .stat-card:nth-child(4) {
    min-width: 230px;
    
    &:before {
      background-image: url("../images/blobs/4.png");
      filter: brightness(55%) sepia(100%) saturate(150%) hue-rotate(297deg); // pink

      @media (max-width: 767px) {
        left: 20px;
      }
    }
  }
}

h3 {
  text-transform: uppercase;
  position: relative;
  margin-top: 20px;
  margin-bottom: 1.75rem;

  &:before {
    content: "";
    width: 8px;
    height: 8px;
    position: absolute;
    background-color: $main;
  }
  &:before {
    top: -22px;
    left: -3px;
  }
}

.step-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2.25rem;

  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 4rem;
  }

  .step-card {
    width: 22.5%;

    @media (max-width: 991px) {
      width: 40%;
      flex-grow: 1;
      margin-bottom: 3rem;
    }

    .header {
      height: 3rem;
      text-transform: uppercase;
    }
    .body {

      &:before {
        color: $main;
        font-style: normal;
        font-weight: 900;
        font-size: 96px;
        line-height: 90px;
        letter-spacing: -2.14px;
        margin-top: -.5rem;
        float: left;

        @media (max-width: 440px) {
          font-size: 72px;
          line-height: 70px;
          letter-spacing: -2.14px;
          margin-top: 0rem;
        }
      }

      span {
        display: block;
        margin-left: 80px;

        @media (max-width: 440px) {
          margin-left: 60px;
        }
      }
    }
    .slogan {
      text-transform: uppercase;
      color: $main;
      text-align: right;
      line-height: 24px;

      @media (max-width: 991px) {
        text-align: left;
      }

      em {
        font-style: normal;
        color: black;
        display: block;
        line-height: 24px;
      }
      span {
        display: block;
      }
    }
      
    &:nth-child(1) .body:before { content: "1"; }
    &:nth-child(2) .body:before { content: "2"; }
    &:nth-child(3) .body:before { content: "3"; }
    &:nth-child(4) .body:before { content: "4"; }
  }
  .step-card-slogan {
    width: 10%;

    @media (max-width: 991px) {
      width: 40%;
      flex-grow: 1;

      .header {
        display: none;
      }
    }
    @media (max-width: 440px) {
      margin-bottom: 0px;
    }
  }
  .step-card {
    position: relative;

    & > * {
      z-index: 4;
      position: relative;
    }
    &:before {
      content: '';
      position: absolute;
      height: 150px;
      width: 200px;
      background-repeat: no-repeat;
      background-size: 150px auto;
      opacity: .8;
      z-index: 1;
      filter: brightness(80%) sepia(100%) saturate(2%) hue-rotate(170deg);   // gray

      @media (max-width: 440px) {
        background-size: 120px auto;
      }
    }

    &:nth-child(1), &:nth-child(2), &:nth-child(3) {
      .body {
        color: $main;
      }
    }
    &:nth-child(1):before {
      top: -15px;
      left: -30px;
      background-image: url("../images/blobs/5.png");

      @media (max-width: 440px) {
        top: 30px;
        left: 10px;
      }
    }
    &:nth-child(2):before {
      top: -20px;
      left: -30px;
      background-image: url("../images/blobs/1.png");

      @media (max-width: 440px) {
        top: 30px;
        left: 10px;
      }
    }
    &:nth-child(3) {
      white-space: pre-wrap;
      &:before {
        top: -20px;
        left: -30px;
        background-image: url("../images/blobs/2.png");

      @media (max-width: 440px) {
        top: 30px;
        left: 10px;
      }
      }
    }
    &:nth-child(4):before {
      top: -20px;
      left: -30px;
      background-image: url("../images/blobs/4.png");

      @media (max-width: 440px) {
        top: 30px;
        left: 10px;
      }
    }
  }
}
.about {
  div[class^="col"], div[class*=" col-"] {
    padding-right: 110px;

    @media (max-width: 440px) {
      padding-right: 0;
    }
  }
  .image-dudes {
    width: 480px;
    height: auto;
    margin-top: 30px;

    @media (max-width: 991px) {
      max-width: 100%;
      margin: 30px auto 0;
      display: block;
    }
  }
}
.debots{
  p {
    max-width: 380px;

    @media (max-width: 991px) {
      max-width: max-content;
    }
  }
  .image-heartful {
    width: 330px;
    height: auto;
    margin: 0 auto;
    display: block;
    margin-top: -20px;

    @media (max-width: 991px) {
      max-width: 100%;
      margin: 0px auto 0;
      display: block;
    }
  }
}
.profits{
  p {
    max-width: 400px;
    
    @media (max-width: 1199px) {
      max-width: 360px;
    }

    @media (max-width: 991px) {
      max-width: max-content;
    }
  }
}
.steps {
  @media (max-width: 767px) {
    overflow: hidden;
  }
}
.calculator-wrapper {
  position: relative;
}
.calculator {
  position: absolute;
  top: 0;
  right: 0.75rem;

  @media (max-width: 991px) {
    position: static;
    margin: 2rem auto 0;
    max-width: 550px;
  }

  h6 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  ul {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    gap: .5rem;
    margin-bottom: 2rem;

    li {
      color: $main;
      border-radius: .75rem;
      border: 1px solid #EBECEC;
      padding: 0.75rem 1rem;
      flex-grow: 1;
      text-align: center;
      cursor: pointer;

      &:hover {
        background-color: saturate(lighten($main, 20), 90);
        border-color: saturate(lighten($main, 20), 90);
        color: white;
        @include transition(0.25s);
      }

      &.active {
        background-color: $main;
        border-color: $main;
        color: white;
        cursor: default;
        @include transition(0.35s);
      }
    }
  }
  .result {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: .35rem;

    div {
      border-radius: .75rem;
      background-color: $gray_background;
      padding: 0.75rem .5rem;
      flex-grow: 0;
      text-align: center;
      cursor: default;
      white-space: nowrap;
      min-width: 75px;

      &:nth-child(5) {
        background-color: $green;
        color: white;
      }
      &:nth-child(1) {
        min-width: 10px;
        padding: 0.75rem 1rem;
      }
    }
    span {
      display: inline-block;
      margin: 0 0.65rem;
    }
    a {
      flex-grow: 10;
      display: block;

      @media (max-width: 500px) {
        width: 100%;
        margin-top: 1.25rem;
      }

      button {
        width: 100%;
        min-width: auto;
      }
    }
  }
}
.panel {
  background-color: white;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0px 4px 4px 0 rgba(darken($SURF_GRAY, 3), 0.03), 0px 12px 8px 0 rgba($SURF_GRAY, 0.03), 0px 16px 16px 0 rgba($SURF_GRAY, 0.03), 0px 42px 32px 0 rgba($SURF_GRAY, .03), 0px 48px 48px 0 rgba($SURF_GRAY, .03);
}

.social {
  ul {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: fit-content;
    padding: 0;

    @media (max-width: 440px) {
      gap: .25rem;
    }
  }

  li {
    list-style-type: none;
    a {
      color: #B6B8BA;
      padding: 1rem;
      display: inline-block;
    }

    &:hover {
      &.twitter a {
        color: #1DA1F2;
      }
      &.youtube a {
        color: #FF0302;
      }
      &.discord a {
        color: #5865F2;
      }
      &.github a {
        color: #191717;
      }
      &.telegram a {
        color: #229ED9;
      }
    }
  }
}

p + a {
  margin-top: 1.5rem;
  text-transform: uppercase;
  display: inline-flex;

  @media (max-width: 991px) {
    margin-bottom: 1rem;
  }

}

a {

  &.outer {
    position: relative;
    padding-right: 1rem !important;
    @include transition(0.15s);

    &:after {
      content: "";
      width: 1rem;
      height: 1rem;
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%230083E0' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      right: 0;
      top: calc(50% - 10px);
      @include transition(0.15s);
    }
    &:hover {
      @include transition(0.35s);

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1H5M5 1V5M5 1L1 5' stroke='%230168b6' stroke-width='1.25' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
         @include transition(0.35s);
      }
    }
  }
}