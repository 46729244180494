
$gray_background: #F4F4F5;
$main: #0185E9;
$gray: #838588;
$white: #ffffff;
$green: #01C44E;
$yellow: #F6BE42;
$pink: #E58392;
$dark: #20262A;

$SURF_BLUE: #0083E0;
$SURF_PINK: #FF7B86;
$SURF_RED: #e23b3b;
$SURF_YELLOW: #F1CD5E;
$SURF_GREEN: #36C05C;

$SURF_WHITE: #FFFFFF;
$SURF_GRAY_BACKGROUND: #F4F4F5;
$SURF_GRAY: #20262A;
$SURF_GRAY_2: #707376;
$SURF_BLACK: #000000;
$SURF_TERTIARY: #838588;
$SURF_SECONDARY: #707376;


$SURF_GRADIENT_BACKGROUND: #dcdce0;
$SURF_GRADIENT_BACKGROUND_WAVE: #e5e5e7;