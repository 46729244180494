@import '../colors.scss';
@import '../mixins.scss';

footer {
  text-align: center;

  .container-fluid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    line-height: 70px;

    @media (max-width: 499px) {
      flex-direction: column;
    }
  }
  
  @media (max-width: 499px) {
    height: auto;
  }

  .copyright {
    text-align: center;
    a {
      text-transform: none;
    }
  }
  a {
    text-transform: uppercase;
    color: #ffffff;
    margin: 0 auto;
    margin-bottom: 2.5rem;
    display: inline-block;
    
    &:hover {
      color: #A9E0FF;
    }
  }
}