@import '../../assets/styles/colors.scss';
@import '../../assets/styles/mixins.scss';



.btn {
  height: 56px;
  padding: 0.4rem 0.75rem;
  font-weight: 500;
  border-radius: 10px;
  @include transition(0.15s);
  text-transform: uppercase;
}

.btn-icon {
  padding-right: 2rem;

  &:after {
    content: '';
    background-repeat: no-repeat;
    background-position: center -2px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    margin-left: 8px;
    @include transition(0.15s);
  }
}

// .btn-primary {
//   background-color: darken($main, 5);
//   border-color: darken($main, 5);

//   &:hover {
//     background-color: desaturate(darken($main, 12), 15);
//     border-color: desaturate(darken($main, 12), 15);
//     @include transition(0.35s);
//   }
// }

.btn.btn-primary {
  border-color: transparent;
  border: none;
  height: 57px;
  border-radius: 16px;
  min-width: 200px;
  @include transition(0.2s);
  position: relative;
  overflow: hidden;
  box-shadow: 0px 2px 2px rgba($main, 0.1), 0px 4px 4px rgba($main, 0.1), 0px 8px 8px rgba($main, 0.1), 0px 16px 16px rgba($main, 0.15);

  span {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    height: 57px;
    width: 100%;
    padding: 0.97rem 0.75rem;
    background-color: $main;
    border-radius: 16px;
    overflow: hidden;
    @include transition(0.1s);
    box-shadow: inset 0px -3px 0 rgba(darken($main, 20), 0.3);

    &:after {
      display: inline-block;
      width: 1rem;
      height: 1.1rem;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 7.5L7.5 15L7.5 10L-2.18557e-07 10L-4.37114e-07 5L7.5 5L7.5 -3.27835e-07L15.5 7.5Z' fill='white'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      margin-left: .35rem;
      content: '';
      margin-right: -1rem;
      vertical-align: middle;
      opacity: 0;
      @include transition(0.1s);
    }
  }

  &:after {
    content: '';
    position: absolute;
    z-index: 5;
    left: 0;
    top: 0;
    width: 0px;
    height: 0px;
    background: radial-gradient( circle closest-side, rgba(lighten($main, 30), 0.3), transparent );
    -webkit-transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
    -moz-transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
    -ms-transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
    transform: translate(calc(-50% + var(--x)), calc(-50% + var(--y)));
    -webkit-transition: height 0.2s ease;
    transition: height 0.2s ease;
    will-change: width,height,transform;
    mix-blend-mode: overlay;
  }

  &:hover {
   background-color: $main;
   border-color: transparent;
   box-shadow: 0px 25px 4px -20px rgba(darken($main, 3), 0.1), 0px 25px 8px -24px rgba($main, 0.2), 0px 26px 26px -14px rgba($main, 0.4), 0px 40px 30px -24px rgba($main, 1), 0px 20px 30px -24px rgba($main, 1), 0px 50px 40px -14px rgba($main, 0.2);
   transform: translateY(-2px);// scale(1.015);// rotateX(15deg) scale3d(1,1.03,1);

   span {
    @include transition(0.25s);
    background: rgb(0,120,219);
    background: linear-gradient(90deg, rgba(0,120,219,1) 0%, rgba(1,133,233,1) 51%, rgba(9,169,255,1) 100%);
    text-shadow: 0px 1px 1px rgba(black, 0.15), 0px 2px 1px rgba(black, 0.25);
    box-shadow: inset 0px -3px 0 rgba(darken($main, 30), 0.3), inset 0px 0px 25px rgba(saturate(lighten($main, 30), 100), 0.6), inset 0px 2px 5px rgba(saturate(lighten($main, 30), 100), 0.3);

    &:after {
      @include transition-property(0.5s, all, 0.2s);
      opacity: 1;
      text-shadow: 0px 1px 1px rgba(black, 0.15), 0px 2px 1px rgba(black, 0.25);
      transform: translateX(.5rem);
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='15' viewBox='0 0 16 15' fill='none' style=' filter: drop-shadow(0px 2px 1px %2300000040);' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.5 7.5L7.5 15L7.5 10L-2.18557e-07 10L-4.37114e-07 5L7.5 5L7.5 -3.27835e-07L15.5 7.5Z' fill='white'/%3E%3C/svg%3E%0A");
    }
   }
   
    &:after {
      width: 250px;
      height: 250px;
    }
  }
  &:focus, &:active {
    &, &:hover, &:focus, &:active {
      background-color: desaturate(darken($main, 10), 15);
      border-color: transparent;
      box-shadow: 0 0 0 0.2rem rgba($main, 0.3) !important;
    }
  }
  &, &:hover, &:focus, &:active {
    &:disabled {
      background-color: desaturate(darken($main, 10), 15);
      border-color: transparent;
      pointer-events: none;
      opacity: 0.75;
    }
  }
  &:active {
    span {
      @include transition(0.25s);
      background: rgb(0,120,219);
      background: linear-gradient(90deg, rgba(0,120,219,1) 0%, rgba(1,133,233,1) 51%, rgba(9,169,255,1) 100%);
      text-shadow: 0px 1px 1px rgba(black, 0.15), 0px 2px 1px rgba(black, 0.25);
      box-shadow: inset 0px 13px 25px rgba(darken($main, 50), 0.0), inset 0px 5px 20px rgba(saturate(darken($main, 30), 100), 0.6), inset 0px 2px 5px rgba(saturate(darken($main, 30), 5), 0.3);
     }
  }
}

.btn-default {
  background-color: $gray;
  border-color: $gray;
  font-size: 20px;
  font-weight: 600;

  &:hover {
   background-color: desaturate(darken($gray, 10), 15);
   border-color: desaturate(darken($gray, 10), 15);
  }
}

.btn.btn-transparent {

  &, &:focus, &:active {
    &, &:focus, &:active {
      background-color: transparent;
      border-color: transparent;
      color: $dark;
    }
  }

  &:hover {
    &, &:hover, &:focus, &:active {
      color: $main;
      background-color: transparent;
      border-color: transparent;
    }
  }
}
.btn-icon-arrow-right {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.75 8H13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2.75L13.25 8L8 13.25' stroke='%23FDFDFD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &:hover:after {
    @include transition(0.35s);
    transform: translateX(3px);
  }
}
.btn-icon-arrow-up-right {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%2320262A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5V4.5H4.5' stroke='%2320262A' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &:hover:after {
    @include transition(0.35s);
    transform: translateX(-3px);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 4.5L4.5 11.5' stroke='%233888FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M11.5 11.5V4.5H4.5' stroke='%233888FF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.dropdown-toggle {
  &:after {
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%2320262A' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    content: '';
    background-repeat: no-repeat;
    background-position: center -2px;
    width: 16px;
    display: inline-block;
    vertical-align: middle;
    height: 16px;
    margin-left: 8px;
    @include transition(0.15s);
    border: none;
  }

  &:hover:after {
    @include transition(0.35s);
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6L8 11L13 6' stroke='%233888FF' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}

.dropdown-menu {
  border-radius: 10px;
  border: none;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 1px 2px 0 rgba(61,75,91,0.03),  0 2px 5px 0 rgba(61,75,91,0.05), 0 5px 12px 0 rgba(33,39,56,0.06), 0 15px 30px 0 rgba(33,39,56,0.1);

  .dropdown-item {
    padding: 0.5rem 1rem;

    &:first-child {
      padding-top: 0.75rem;
    }

    &:last-child {
      padding-bottom: 0.75rem;
    }
    
    &:hover {
      background-color: #F7F8FA;
    }
  }
}

.btn-lg {
  height: 56px;
  min-width: 132px;
  font-size: 1.075rem;
  letter-spacing: -.02rem;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
}

.btn-xl {
  height: 62px;
  min-width: 180px;
  font-size: 1.075rem;
  letter-spacing: -.02rem;
  border-radius: 12px;
  font-size: 20px;
  font-weight: 600;
}